<template>
  <h1>{{ $translator.t('RESET_PASSWORD_HEADER') }}</h1>

  <div class="col-md-4 offset-md-4">
    <div class="mb-3">
      <label for="emailInput" class="form-label">
        {{ $translator.t('RESET_PASSWORD_INPUT_LABEL') }}
      </label>
      <input type="email" class="form-control" id="emailInput" v-model="email">
    </div>
    <div class="mb-3">
      <button class="btn btn-primary" @click="resetPassword">{{ $translator.t('RESET_PASSWORD_BUTTON_VALUE') }}</button>
    </div>
  </div>

  <div class="alert alert-success mt-3" v-if="sendedEmail">{{ $translator.t('CHECK_EMAIL_MESSAGE') }}</div>
</template>

<script>
import forgotPassword from "@/composables/user/forgotpassword";

export default {
  data() {
    return {
      email: '',
      sendedEmail: false,
    }
  },
  methods: {
    resetPassword() {
      if (this.email.isEmpty !== false) {
        forgotPassword({
          'email': this.email
        })
            .then(() => {
              this.sendedEmail = true
            })
            .catch(err => console.log(err))
      }
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 24px;
  margin-top: 10px;
}
</style>
