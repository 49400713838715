import { API_BASE_URL } from '@/constants/constants';

async function patchResetPassword(token = '', data = {}) {
    const response = await fetch(API_BASE_URL + '/reset-password?token=' + token, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData)
    }

    return response.json();
}

export default patchResetPassword
