<template>
  <h1>{{ $translator.t('ENTER_NEW_PASSWORD') }}</h1>

  <div class="col-md-4 offset-md-4">
    <div class="mb-3">
      <PasswordForm
          :errors="errors"
          @password="updatePassword"
      />
    </div>
    <div class="mb-3">
      <button type="submit" class="btn btn-primary" :disabled="!isFormValid" @click="resetPassword">
        {{ $translator.t('RESET_PASSWORD_BUTTON') }}
      </button>
    </div>
  </div>

  <div class="alert alert-danger mt-3" v-if="errors.message">{{ errors.message }}</div>
</template>

<script>
import {useRouter} from "vue-router";
import patchResetPassword from "@/composables/user/patchResetPassword";
import PasswordForm from "@/components/Password/PasswordForm.vue";
import translator from "@/utils/translator";

export default {
  components: {PasswordForm},
  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      isPasswordValid: false,
      hasValidConfirm: false,
      errors: {},
      router: useRouter(),
    };
  },
  computed: {
    isFormValid() {
      return this.isPasswordValid && this.hasValidConfirm;
    },
  },
  methods: {
    updatePassword(data) {
      this.newPassword = data.newPassword;
      this.confirmPassword = data.confirmationPassword;
      this.isPasswordValid = data.isPasswordValid;
      this.hasValidConfirm = data.hasValidConfirm;
    },
    resetPassword() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (!token) {
        this.errors.message = translator.t('INVALID_TOKEN');
        return;
      }

      patchResetPassword(token, {
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      })
          .then(() => {
            this.router.push("/");
          })
          .catch(() => {
            this.isPasswordValid = false;
            this.hasValidConfirm = false;
            this.errors.message = translator.t('RESET_PASSWORD_FAILED');
          });
    },
  },
};
</script>
