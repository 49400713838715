<template>
  <nav v-if="isLoggedIn" class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{ name: 'injection' }" @click="closeMenu">
              {{ $translator.t('INJECTION') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{ name: 'stroke' }" @click="closeMenu">
              {{ $translator.t('STROKE') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{ name: 'stock' }" @click="closeMenu">
              {{ $translator.t('STOCK') }}
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ $translator.t('MY_ACCOUNT') }}
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" :to="{ name: 'panel' }" @click="closeMenu">
                  {{ $translator.t('SETTINGS') }}
                </router-link>
              </li>
              <li><a class="dropdown-item" href="#" @click.prevent="logout">{{ $translator.t('LOGOUT') }}</a></li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="navbar-text me-3">
        <label class="custom-checkbox">
          <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="darkModeSwitch"
              v-model="isDarkMode"
              @change="toggleDarkMode">
          <span class="checkbox-icon"></span>
        </label>
      </div>

      <div class="navbar-text me-2">
        <select class="form-select form-select-sm"
                aria-label="Language select"
                v-model="language"
                @change="updateLanguage">
          <option :value="$translator.t('LANG_PL')">{{ $translator.t('LANG_PL') }}</option>
          <option :value="$translator.t('LANG_EN')">{{ $translator.t('LANG_EN') }}</option>
        </select>
      </div>
    </div>
  </nav>

  <nav v-else class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="{ name: 'login' }">
        {{ $translator.t('FACTOR_USE') }}
      </router-link>

      <div class="d-flex ms-auto align-items-center">
        <div class="navbar-text me-3">
          <label class="custom-checkbox">
            <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="darkModeSwitch"
                v-model="isDarkMode"
                @change="toggleDarkMode">
            <span class="checkbox-icon"></span>
          </label>
        </div>

        <div class="navbar-text me-2">
          <select class="form-select form-select-sm"
                  aria-label="Language select"
                  v-model="language"
                  @change="updateLanguage">
            <option :value="$translator.t('LANG_PL')">{{ $translator.t('LANG_PL') }}</option>
            <option :value="$translator.t('LANG_EN')">{{ $translator.t('LANG_EN') }}</option>
          </select>
        </div>
      </div>
    </div>
  </nav>
  <router-view/>
</template>

<script>
import {onMounted, onUnmounted, computed, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {isTokenExpired} from "@/utils/authUtils";
import patchUserDarkmode from "@/composables/user/patchUserDarkmode";
import patchUserLanguage from "@/composables/user/patchUserLanguage";
import translator from "@/utils/translator";

export default {
  methods: {
    closeMenu() {
      const navbarToggler = document.querySelector('.navbar-toggler');
      const navbarCollapse = document.querySelector('.navbar-collapse');

      if (navbarToggler && navbarCollapse.classList.contains('show')) {
        navbarToggler.click();
      }
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
    updateLanguage() {
      this.language = this.language.toLowerCase();
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const isLoggedIn = computed(() => store.getters.isAuthenticated);

    const isDarkMode = computed({
      get: () => store.state.darkmode,
      set: async (value) => {
        store.commit("setDarkMode", value);
        document.documentElement.setAttribute("data-bs-theme", value ? "dark" : "light");
        if (isLoggedIn.value) {
          await patchUserDarkmode({darkmode: value});
        }
      },
    });

    const language = computed({
      get: () => store.state.language.toUpperCase(),
      set: (value) => {
        const lang = value.toLowerCase();
        store.commit("setLanguage", lang);
        translator.changeLanguage(lang);
        if (isLoggedIn.value) {
          patchUserLanguage({language: lang});
        }
      },
    });

    const checkTokenValidity = () => {
      const token = localStorage.getItem('token');
      if (!token || isTokenExpired(token)) {
        if (store.getters.isAuthenticated) {
          logout();
        }
      }
    };

    const logout = () => {
      store.dispatch('logout');
      router.push('/login');
    };

    watch(() => router.currentRoute.value, () => {
      checkTokenValidity();
    }, {immediate: true});

    onMounted(() => {
      checkTokenValidity();
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    const interval = setInterval(checkTokenValidity, 60 * 1000);

    return {
      isLoggedIn,
      isDarkMode,
      language,
      logout,
      toggleDarkMode: () => isDarkMode.value,
      updateLanguage: (event) => language.value = event.target.value.toLowerCase(),
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}

#nav {
  padding: 30px;
}

.navbar {
  margin-bottom: 20px;
}

#nav a {
  font-weight: bold;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkbox-icon {
  display: inline-block;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  transition: color 0.3s;
  vertical-align: middle;
  cursor: pointer;
}

.custom-checkbox .checkbox-icon::before {
  content: "🌙";
}

.custom-checkbox input[type="checkbox"]:checked + .checkbox-icon::before {
  content: "☀️";
}
</style>
