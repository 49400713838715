<template>
  <div class="row d-flex flex-column flex-md-row">
    <div class="col-md-4 offset-md-2 order-md-last mb-4 mb-md-0">
      <h2>{{ $translator.t('LOGIN_HEADER') }}</h2>
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="usernameInput" class="form-label">{{ $translator.t('USERNAME') }}</label>
          <input type="text" class="form-control" id="usernameInput" v-model="username">
        </div>
        <div class="mb-3">
          <label for="passwordInput" class="form-label">{{ $translator.t('PASSWORD') }}</label>
          <input type="password" class="form-control" id="passwordInput" v-model="password">
        </div>

        <div>
          <button type="submit"
                  class="btn btn-primary"
                  :disabled="username === '' || password === ''">
            {{ $translator.t('LOGIN_BUTTON') }}
          </button>
        </div>

        <div class="forgot-password">
          <router-link class="btn btn-link" :to="{ name: 'forgot_password' }">
            {{ $translator.t('FORGOT_PASSWORD_LOGIN_LABEL') }}
          </router-link>
        </div>

        <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
      </form>
    </div>

    <div class="col-md-4 offset-md-1 order-md-first">
      <h2>{{ $translator.t('REGISTER_HEADER') }}</h2>
      <div class="mb-3">
        <label for="someInput" class="form-label">{{ $translator.t('USERNAME') }}</label>
        <input type="text" class="form-control" id="someInput" v-model="register.username">
        <span v-if="this.errors_register.username" class="error">{{ this.errors_register.username }}</span>
      </div>

      <PasswordForm
          :errors="errors_register"
          @password="updatePassword"
      />

      <button type="submit"
              class="btn btn-primary"
              :disabled="isFormRegisterValid === false"
              @click="registerUser">
        {{ $translator.t('REGISTER_BUTTON') }}
      </button>

      <div class="alert alert-danger mt-3" v-if="registerMessage.error">{{ registerMessage.error }}</div>
      <div class="alert alert-success mt-3" v-if="registerMessage.success">{{ registerMessage.success }}</div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {API_BASE_URL} from "@/constants/constants";
import PasswordForm from "@/components/Password/PasswordForm.vue";
import registerUser from "@/composables/user/registerUser";
import axios from 'axios';
import translator from "@/utils/translator";

export default {
  components: {PasswordForm},
  data() {
    return {
      register: {
        username: '',
        password: '',
        confirmPassword: '',
      },
      isPasswordValid: false,
      hasValidConfirm: false,
      errors_register: {},
      registerMessage: {},
    }
  },
  methods: {
    updatePassword(data) {
      this.register.password = data.newPassword;
      this.register.confirmPassword = data.confirmationPassword;
      this.isPasswordValid = data.isPasswordValid;
      this.hasValidConfirm = data.hasValidConfirm;
    },
    registerUser() {
      if (this.isFormRegisterValid === true) {
        registerUser({
          username: this.register.username,
          password: this.register.password,
          confirm_password: this.register.confirmPassword,
          darkmode: Boolean(localStorage.getItem('darkmode')),
          language: localStorage.getItem('user_language'),
        })
            .then(() => {
              this.register.username = ''
              this.register.password = ''
              this.register.confirmPassword = ''

              this.isPasswordValid = false;
              this.hasValidConfirm = false;

              this.registerMessage.success = translator.t('USER_CREATED_SUCCESS')
            })
            .catch(err => {
              this.isPasswordValid = false;
              this.hasValidConfirm = false;

              this.registerMessage.error = err.message;
            })
      }
    }
  },
  computed: {
    isUsernameValid() {
      return this.register.username !== '' && this.register.username.length >= 3;
    },
    isFormRegisterValid() {
      return this.isUsernameValid && this.isPasswordValid && this.hasValidConfirm;
    }
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');

    const login = async () => {
      try {
        const response = await axios.post(API_BASE_URL + '/api/login_check', {
          username: username.value,
          password: password.value
        });
        localStorage.setItem('token', response.data.token);
        store.dispatch('login', username.value);
        router.push('/');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          errorMessage.value = translator.t('INCORRECT_LOGIN_DATA');
        } else {
          errorMessage.value = translator.t('LOGIN_FAILED');
        }
        console.error('Error login:', error);
      }
    };

    return {
      username,
      password,
      login,
      errorMessage,
    };
  },
};
</script>

<style>
h1 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.error {
  color: red;
  margin-top: 5px;
}

.forgot-password {
  text-align: right;
}
</style>
